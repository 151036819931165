import {Flex, Col, Row} from 'antd';

import './App.css';

function App() {
    const contentMap = (name) => {
        switch (name) {
            case "0-0":
                return <a href='https://v2ai.cn' target='_blank' rel="noreferrer">
                    <div key={name} className={"card"}>
                        <div>V2AI</div>
                        <div style={{
                            fontSize: 8
                        }}>.CN
                        </div>
                    </div>
                </a>
            case "0-1":
                return <a href='https://www.zhihu.com/people/HaveTwoBrush' target='_blank' rel="noreferrer">
                    <div key={name} className={"card"}>Zhihu</div>
                </a>
            case "0-2":
                return <a href='https://github.com/Ailln' target='_blank' rel="noreferrer">
                    <div key={name} className={"card"}>Github</div>
                </a>
            case "0-3":
                return <a href='https://blog.ailln.com/v2ai/' target='_blank' rel="noreferrer">
                    <div key={name} className={"card"}>
                        <div>V2AI</div>
                        <div style={{
                            fontSize: 8
                        }}>BLOG
                        </div>
                    </div>
                </a>
            case "1-0":
                return <a href='https://mdicl.com' target='_blank' rel="noreferrer">
                    <div key={name} className={"card"}>
                        <div>MDICL</div>
                        <div style={{
                            fontSize: 8
                        }}>.COM
                        </div>
                    </div>
                </a>
            case "1-1":
                return <a href='https://arxivs.com' target='_blank' rel="noreferrer">
                    <div key={name} className={"card"}>
                        <div>ARXIVS</div>
                        <div style={{
                            fontSize: 8
                        }}>.COM
                        </div>
                    </div>
                </a>
            case "1-2":
                return <a href='https://dovolopor.com' target='_blank' rel="noreferrer">
                    <div key={name} className={"card"}>
                        <div>DOV</div>
                        <div style={{
                            fontSize: 8
                        }}>OLOPOR.COM
                        </div>
                    </div>
                </a>
            case "1-3":
                return <a href='https://fuzi.ailln.com' target='_blank' rel="noreferrer">
                    <div key={name} className={"card"}>
                        FUZI
                    </div>
                </a>
            default:
                return <div key={name} style={{
                    width: 'calc(15vh)',
                    height: 'calc(15vh)',
                    backgroundColor: "#f3f3f3"
                }}></div>
        }
    }
    return (
        <div className="pages">
            <div className={"page"}>
                <Row style={{height: 'calc(10vh)', background: 'white'}}>
                    <Col sm={4} lg={2} style={{margin: 'auto'}}>
                        <img src='matrix.svg' alt=''/>
                    </Col>
                    <Col sm={16} lg={20} style={{margin: 'auto'}}></Col>
                    <Col sm={4} lg={2} style={{margin: 'auto'}}>
                        <img src='matrix.svg' alt=''/>
                    </Col>
                </Row>
                <Row style={{
                    height: 'calc(80vh)',
                    background: 'url(./ailln_logo_with_circle.svg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}>
                    <Col sm={0} lg={6} style={{margin: 'auto'}}>
                    </Col>
                    <Col sm={24} lg={12} style={{margin: 'auto'}}>
                        <img src='Ailln.svg' style={{width: '80%', marginTop: 54}} alt=''/>
                        <div style={{fontSize: 42, marginTop: 50}}>DATA & ALGORITHM</div>
                    </Col>
                    <Col sm={0} lg={6} style={{margin: 'auto'}}>
                    </Col>
                </Row>
                <Row style={{height: 'calc(10vh)', background: 'white'}}>
                    <Col sm={4} lg={2} style={{margin: 'auto'}}>
                        <img src='matrix.svg' alt=''/>
                    </Col>
                    <Col sm={16} lg={20} style={{margin: 'auto'}}>
                        <a href='https://beian.miit.gov.cn/' target='_blank' rel="noreferrer">
                            <img src='beian.svg' alt=''/>
                        </a>
                    </Col>
                    <Col sm={4} lg={2} style={{margin: 'auto'}}>
                        <img src='matrix.svg' alt=''/>
                    </Col>
                </Row>
            </div>
            <div className={"page"}>
                <Row style={{height: 'calc(10vh)', background: 'white'}}>
                    <Col sm={4} lg={2} style={{margin: 'auto'}}>
                        <img src='matrix.svg' alt=''/>
                    </Col>
                    <Col sm={16} lg={20} style={{margin: 'auto'}}></Col>
                    <Col sm={4} lg={2} style={{margin: 'auto'}}>
                        <img src='matrix.svg' alt=''/>
                    </Col>
                </Row>
                <Row style={{height: 'calc(80vh)', background: 'white'}}>
                    <Col sm={0} lg={6} style={{margin: 'auto'}}>
                    </Col>
                    <Col sm={24} lg={12} style={{margin: 'auto'}}>
                        <Flex vertical={true} align={"center"} justify={"center"} gap={4}>
                            {Array.from({length: 4}).map((_, i) => (
                                <Flex k={i} vertical={false} align={"center"} justify={"center"} gap={4}>
                                    {Array.from({length: 4}).map((_, j) => (
                                        contentMap(`${i}-${j}`)
                                    ))}
                                </Flex>
                            ))}
                        </Flex>
                    </Col>
                    <Col sm={0} lg={6} style={{margin: 'auto'}}>
                    </Col>
                </Row>
                <Row style={{height: 'calc(10vh)', background: 'white'}}>
                    <Col sm={4} lg={2} style={{margin: 'auto'}}>
                        <img src='matrix.svg' alt=''/>
                    </Col>
                    <Col sm={16} lg={20} style={{margin: 'auto'}}>
                        <a href='https://beian.miit.gov.cn/' target='_blank' rel="noreferrer">
                            <img src='beian.svg' alt=''/>
                        </a>
                    </Col>
                    <Col sm={4} lg={2} style={{margin: 'auto'}}>
                        <img src='matrix.svg' alt=''/>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default App;
